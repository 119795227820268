@keyframes btn-pisca {
  0% { opacity: 0; }
  50% { opacity: 0.5; }
  100% { opacity: 1; }
}
.btn-pisca {
/* background-color:#ce300c;

color:#fff;
text-align: center;
font-size: 1.2em;
padding: 0.6em 0.8em;
border-radius: 4px; 
display: inline-block;
text-decoration: none; */

/* -webkit-animation: btn-pisca .9s linear infinite;
-moz-animation: btn-pisca .9s linear infinite;
-ms-animation: btn-pisca .9s linear infinite;
-o-animation: btn-pisca .9s linear infinite; */
animation: btn-pisca .9s linear infinite;
}