.input {
  display: "flex";
}
.moeda {
  /* width: 170px;
  font-size: 20px;
  color: #111;
  border-radius: 5px 5px 5px 5px;
  pandding:5px;
  background-color:#999999;
  height:50px */
}
